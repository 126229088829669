import React, { useEffect, useState } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components"
import ContentContainer from "../components/Core/Container/ContentContainer"
import { isBrowser } from "../services/browser"
import { graphql } from "gatsby"
import fonts from "../styles/fonts"
import colors from "../styles/colors"
import breakpoints from "../styles/breakpoints"
import Section from "../components/Search/Section"

const SearchPageContainer = styled.div`
  @media (min-width: ${breakpoints.xl}) {
    padding: 0 53px;
  }
  @media (min-width: ${breakpoints.xxl}) {
    padding: 0 106px;
  }
`

const TitleSection = styled.div`
  margin-top: 95px;
  margin-bottom: 32px;

  h1 {
    ${fonts.canelaThin};
    color: ${colors.grey};
    font-size: 40px;
    line-height: 1;
    text-align: center;
    padding: 0 20px;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 167px;
    margin-bottom: 43px;
    h1 {
      font-size: 72px;
    }
  }
`

const Filters = styled.div`
  display: grid;
  grid-gap: 11px;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid ${colors.grey};
  }
`

const Filter = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${(props) => (props.active ? colors.white : colors.grey)};
  font-size: 18px;
  line-height: 1.6;
  width: 100%;
  border-radius: 15px;
  border: 1px solid ${colors.grey};
  padding: 14px;
  text-align: center;
  background: ${(props) => (props.active ? colors.orangeDark : "transparent")};
  cursor: pointer;
  @media (min-width: ${breakpoints.md}) {
    border-radius: 0;
    border: none;
    border-right: 1px solid ${colors.grey};
    :last-of-type {
      border-right: none;
    }
  }
`

const Sections = styled.div`
  margin-top: 26px;
`

const SearchPage = (props) => {
  const { data, location } = props
  const path = location.pathname
  const articles = data.articles.nodes
  const products = data.products.nodes
  const productContents = data.productContents.nodes
  const [query, setQuery] = useState(null)
  const [activeTab, setActiveTab] = useState(null)

  useEffect(() => {
    if (isBrowser()) {
      const searchParams = location.search.replace("?query=", "")
      if (searchParams) {
        setQuery(decodeURI(searchParams))
      }
    }
  }, [location.search])

  const productsWithContent = products.map((product) => {
    let content = productContents.find(
      (contentData) => contentData.productSlug === product.slug
    )
    let productData = product
    if (content) {
      productData = Object.assign(productData, content)
    }
    return productData
  })

  const filterContentsBySearch = () => {
    let filteredArticles = []
    let filteredProducts = []
    if (!query) {
      return { products: [], articles: [] }
    } else {
      let searchWords = query.toLowerCase().split(" ").join("")

      filteredArticles = articles.filter((article) => {
        const { category, title, tags } = article
        const fixedCategory = category.title.toLowerCase().split(" ").join("")
        const fixedTitle = title.toLowerCase().split(" ").join("")
        const lowerCasedTags = tags.map((tag) => tag.toLowerCase())

        if (
          fixedCategory.includes(searchWords) ||
          fixedTitle.includes(searchWords) ||
          lowerCasedTags.some((tag) => tag.includes(searchWords))
        ) {
          return article
        }
      })
      filteredProducts = productsWithContent.filter((product) => {
        const { tags, name, slug } = product
        const fixedName = name.toLowerCase().split(" ").join("")
        const lowerCasedTags = tags.map((tag) => tag.toLowerCase())
        const mergedLowerCasedTags = tags.map((tag) =>
          tag.split("-").join("").toLowerCase()
        )

        if (
          slug.includes(searchWords) ||
          fixedName.includes(searchWords) ||
          lowerCasedTags.some((tag) => tag.includes(searchWords)) ||
          mergedLowerCasedTags.some((tag) => tag.includes(searchWords))
        ) {
          return product
        }
      })
    }

    return { products: filteredProducts, articles: filteredArticles }
  }

  const filteredContents = filterContentsBySearch()

  const productsLength = filteredContents.products.length
  const articlesLength = filteredContents.articles.length

  const total =
    articlesLength > 0 || productsLength > 0
      ? articlesLength + productsLength
      : 0

  const filters = [
    { title: "All results", id: null, total: total },
    { title: "Products", id: "products", total: productsLength },
    { title: "Content", id: "articles", total: articlesLength },
  ]

  return (
    <Layout>
      <Seo title={"Search"} description={"Search"} path={path} />
      <SearchPageContainer>
        <ContentContainer>
          <TitleSection>
            <h1>
              {total} results for "{query}"
            </h1>
          </TitleSection>
          <Filters>
            {filters.map((filter, index) => {
              return (
                <Filter
                  key={index}
                  active={activeTab === filter.id}
                  onClick={() => setActiveTab(filter.id)}
                >
                  {filter.title} ({filter.total})
                </Filter>
              )
            })}
          </Filters>
          <Sections>
            {Object.keys(filteredContents).map((key, index) => {
              const visible = activeTab === key || !activeTab
              return visible ? (
                <Section
                  contents={filteredContents[key]}
                  type={key}
                  key={index}
                />
              ) : null
            })}
          </Sections>
        </ContentContainer>
      </SearchPageContainer>
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query SearcchPageQuery {
    page: contentfulSearch(slug: { eq: "search" }) {
      metaTitle
      metaDescription
    }
    articles: allContentfulArticle {
      nodes {
        category {
          title
          slug
          categoryColor
        }
        blogOrder
        tags
        slug
        title
        thumbnail {
          gatsbyImageData(width: 636, aspectRatio: 1)
        }
      }
    }
    products: allShopifyProduct {
      nodes {
        product_id: shopifyId
        name: title
        description
        price: priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        slug: handle
        tags
        images {
          gatsbyImageData(width: 626, aspectRatio: 1)
        }
      }
    }
    productContents: allContentfulProductContent {
      nodes {
        productSlug
        productMedias {
          file {
            url
            contentType
          }
          gatsbyImageData(width: 626, aspectRatio: 1)
        }
        hoverImage {
          file {
            contentType
            url
          }
          gatsbyImageData(width: 626, aspectRatio: 1)
        }
      }
    }
  }
`
