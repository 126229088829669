import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ProductCard from "../PLP/ProductCard"
import ArticleCard from "../Blog/ArticleCard"

const SectionContainer = styled.div`
  padding-top: 46px;
  padding-bottom: 120px;
  border-top: 1px solid ${colors.grey};
  :first-of-type {
    border-top: none;
  }

  @media (min-width: ${breakpoints.md}) {
    padding-top: 77px;
  }
`

const SectionTitle = styled.h2`
  ${fonts.canelaThin};
  font-size: 40px;
  line-height: 1;
  text-align: center;
  margin-bottom: 67px;

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: 89px;

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: ${breakpoints.xl}) {
    grid-gap: 96px;
  }
`

const Section = (props) => {
  const { contents, type } = props
  const isProduct = type === "products"
  return (
    <SectionContainer>
      <SectionTitle>{isProduct ? "Product" : "Content"}</SectionTitle>
      <Grid>
        {contents.map((content, index) => {
          return isProduct ? (
            <ProductCard {...content} key={index} />
          ) : (
            <ArticleCard {...content} key={index} search/>
          )
        })}
      </Grid>
    </SectionContainer>
  )
}

export default Section
